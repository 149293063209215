import styled from "styled-components"
import { lightGray, labelColor } from "mill/utils/colors"
import { headingFont } from "mill/utils/settings"

export const AnswerRow = styled.td`
  ${props =>
    props.correct &&
    `
    color: ${props.theme.colors.primary};
    font-weight: bold;
  `};
`

export const Title = styled.h1`
  border-top: 3px dashed ${lightGray};
  border-bottom: 3px dashed ${lightGray};
  padding: 3rem 10rem;
  width: 100%;
  font-size: 2rem;
  font-weight: 300;
  font-family: ${headingFont};
  text-align: center;
`

export const Correct = styled.span`
  color: ${props => props.theme.colors.primary};
  font-size: 2rem;
  margin-left: 1rem;
`
